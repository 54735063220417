<template>
  <div>
    <section>
      <ZHeader title="メッセージ" sub="一括提案の作成" showNotification>
        <template v-slot:rightIcons>
          <ZNotification color="#777" />
        </template>
      </ZHeader>
    </section>
    <div class="z-section" v-if="!isLoading">
      <div class="z-section__inner bulkmessages">
        <!-- 提案物件 -->
        <section class="bulkmessages--section">
          <h1 class="bulkmessages--header">提案物件</h1>

          <template>
            <div class="selectRealEstate">
              <p class="selectRealEstate--header">
                提案する物件をお選びください
              </p>
              <p class="selectRealEstate--subheader">
                添付を1件に厳選いただくと、提案受け入れの確率が高くなります。
              </p>
              <b-button type="is-primary" @click="onOpenModalSelectRealEstate">
                添付する物件を選ぶ
              </b-button>

              <SelectedRealEstates
                :mode="modeModalSelectRealEstate"
                :selectedRealEstates="selectedRealEstates"
              />
            </div>
          </template>
        </section>
        <!-- /提案物件 -->

        <!-- 提案内容 -->
        <section class="bulkmessages--section">
          <h1 class="bulkmessages--header">提案内容</h1>

          <!-- テンプレート選択 -->
          <div class="columns">
            <div class="column is-full">
              <ZRadio
                label="テンプレート選択"
                class="bulkmessages--customtemplate"
                name="customTemplate"
                :options="$$ks.message.templateTypes"
                v-model="patternMessage.customTemplate"
                @input="onChangeCustomTemplates"
              />
              <template v-if="customTemplates.length > 0">
                <div class="z-radio bulkmessages--customtemplate">
                  <template v-for="item in customTemplates">
                    <ZField label="" hideRequired :key="item.id">
                      <div class="block">
                        <b-radio
                          name="customTemplate"
                          v-model="patternMessage.customTemplate"
                          type="is-info"
                          :native-value="item.id"
                          @input="onChangeCustomTemplates"
                        >
                          テンプレート：{{
                            $$createTemplateMessage$replaceName(
                              $$createTemplateMessage$replaceScore(
                                item.data.label
                              )
                            )
                          }}
                        </b-radio>
                      </div>
                    </ZField>
                  </template>
                </div>
              </template>
              <b-button
                class="bulkmessages--createtemplate"
                type="is-default"
                @click="onOpenModalCreateTemplateMessage"
              >
                テンプレートを作成する
              </b-button>
            </div>
          </div>
          <!-- /テンプレート選択 -->

          <div class="columns is-suggestions">
            <div class="column is-full">
              <ZSuggestionsCheckbox
                label="提案の特徴"
                v-model="patternMessage.data.suggestions"
              />
            </div>
          </div>

          <!-- 件名 -->
          <div
            class="columns"
            :class="{ 'bulkmessages--border__title': !bulma.isMobile }"
          >
            <div class="column is-full">
              <ZInput
                label="件名"
                class="bulkmessages--title"
                placeholder="件名を入力してください"
                v-model="patternMessage.data.title"
                type="text"
                maxlength="9999"
                validate="required"
                isRequired
              />
            </div>
          </div>
          <!-- /件名 -->

          <!-- メッセージ -->
          <div
            class="columns"
            :class="{ 'bulkmessages--border__message': !bulma.isMobile }"
          >
            <div class="column is-full">
              <ZTextarea
                label="メッセージ"
                class="bulkmessages--content"
                :rows="8"
                v-model="patternThread.contents"
                type="textarea"
                maxlength="9999"
                placeholder="メッセージを入力してください"
                validate="required"
                isRequired
              />
            </div>
          </div>
          <!-- /メッセージ -->

          <!-- 投資家のスコア名を入力する -->
          <div class="columns">
            <div class="column is-full">
              <div class="bulkmessages--scorename">
                <b-button
                  class="bulkmessages--scorename__button"
                  type="is-primary"
                  outlined
                  rounded
                  @click="onAddInvestorScore"
                >
                  投資家のスコア名を入力する
                </b-button>
                <b-tooltip
                  v-if="!bulma.isMobile"
                  :label="$$createTemplateMessage$labelTooltip()"
                  multilined
                  position="is-top"
                  type="is-dark"
                >
                  <img
                    class="bulkmessages--scorename__help"
                    src="@/assets/investor/help.png"
                  />
                </b-tooltip>
              </div>
            </div>
          </div>
          <!-- /投資家のスコア名を入力する -->

          <!-- メッセージ文　プレビュー -->
          <div class="columns">
            <div class="column is-full">
              <ZField
                label="メッセージ文　プレビュー"
                class="bulkmessages--preview"
                hideRequired
              >
                <p class="bulkmessages--preview--title">
                  {{
                    $$createTemplateMessage$replaceName(
                      $$createTemplateMessage$replaceScore(
                        patternMessage.data.title
                      )
                    ) || "件名を入力してください"
                  }}
                </p>
                <div class="bulkmessages--preview--suggestions">
                  <SuggestionsView
                    :suggestions="patternMessage.data.suggestions"
                  />
                </div>
                <p class="bulkmessages--preview--content">
                  {{
                    $$createTemplateMessage$replaceName(
                      $$createTemplateMessage$replaceScore(
                        patternThread.contents
                      )
                    ) || "メッセージを入力してください"
                  }}
                </p>
              </ZField>
            </div>
          </div>
          <!-- /メッセージ文　プレビュー -->

          <!-- 添付ファイル -->
          <div class="columns">
            <div class="column is-full">
              <ZField label="添付ファイル">
                <p class="bulkmessages--mention">
                  ※添付ファイルは、ファイル個数・データ容量に制限はありません（無制限で添付送信可能です。）
                </p>
                <ZDocsListSelect
                  label=""
                  v-model="patternThread.docs"
                  uploadPath="docs"
                />
              </ZField>
            </div>
          </div>
          <!-- /添付ファイル -->
        </section>
        <!-- /提案内容 -->
      </div>
    </div>

    <div class="z-section" v-if="!isLoading">
      <div class="terms">
        <div class="terms--wrapper">
          <p class="terms--title">
            <span>提案件数</span>
            <span class="terms--title__number">
              {{ targetUids.length }}
            </span>
            <span class="terms--title__highlight">件</span>
          </p>
          <p class="terms--subtitle">
            StockFormer利用規約に基づき、以下注意事項を確認してください。
          </p>
          <ZCheckbox
            label=""
            v-model="checkedTerms"
            :options="$$ks.message.terms"
            hideRequired
          />
          <b-button
            class="z-button"
            type="is-primary"
            rounded
            :disabled="isDisable()"
            @click="onSend"
          >
            送信する
          </b-button>
        </div>
      </div>
    </div>

    <!-- 物件選択オーバーレイ -->
    <ModalSelectRealEstates
      ref="modalSelectRealEstate"
      :mode="modeModalSelectRealEstate"
    />
    <!-- /物件選択オーバーレイ -->

    <!-- メッセージテンプレート作成オーバーレイ -->
    <ModalCreateTemplateMessage
      ref="modalCreateTemplateMessage"
      :data="customTemplates"
      :mode="$$createTemplateMessage$modes().bulkmessages"
    />
    <!-- /メッセージテンプレート作成オーバーレイ -->
  </div>
</template>

<script>
// import InvestorProfile from "@/views/realtor/setting/investors/parts/InvestorProfile";
import ModalSelectRealEstates from "@/views/realtor/common/ModalSelectRealEstates";
import ModalCreateTemplateMessage from "@/views/realtor/common/ModalCreateTemplateMessage";
import SelectedRealEstates from "@/views/realtor/common/SelectedRealEstates";
import RealtorType from "@/types/realtorType";
import DisplayTextMixin from "@/views/realtor/setting/investors/parts/DisplayTextMixin";
import CreateTemplateMessageMixin from "@/views/realtor/common/CreateTemplateMessageMixin";
import * as InvestorModule from "@/modules/investor/investor";
import * as RealtorModule from "@/modules/realtor/realestate";

export default {
  name: "bulkmessages-new",
  mixins: [DisplayTextMixin, CreateTemplateMessageMixin],
  components: {
    // InvestorProfile,
    ModalSelectRealEstates,
    ModalCreateTemplateMessage,
    SelectedRealEstates,
  },
  data: function () {
    return {
      isLoading: true,
      mode: null,
      modeModalSelectRealEstate: 1,
      customTemplates: [],
      targetUids: [],
      investors: [],
      selectedRealEstates: [],
      checkedTerms: [],
      patternMessage: null,
      patternThread: null,
      elemFocus: {
        previous: null,
        current: null,
        typePrevious: null,
        typeCurrent: null,
      },
      loginUser: null,
    };
  },
  methods: {
    // 初期化
    async init() {
      const loadingComponent = this.$loading.open();
      try {
        this.isLoading = true;

        // 送信モード
        this.mode = parseInt(this.$route.query.mode);

        if (!this.$route.query.uid || !this.$route.query.type) return;

        if (this.$route.query.uid instanceof Array) {
          this.$route.query.uid.forEach((uid) => {
            this.targetUids.push(uid);
          });
        } else {
          this.targetUids.push(this.$route.query.uid);
        }

        if (this.targetUidslength === 0) return;

        this.patternMessage = this.$$message$getNewMessage();
        this.patternThread = this.$$message$getNewThread();
        this.patternMessage.data.type = parseInt(this.$route.query.type);
        this.patternMessage.customTemplate =
          this.$$ks.message.templateTypes[0].value;
        this.patternMessage.investor =
          await this.$$investor$loadUserWithMessage(this.targetUids[0]);

        // 投資家を取得
        await this.getInvestors();

        // 物件を取得
        await this.getDefaultRealEstate();

        // カスタムテンプレートリストを取得
        this.customTemplates =
          await this.$$createTemplateMessage$getCustomTemplates();

        const user = await this.$$realtor$loadUser(this.auth.uid);
        this.loginUser = user.data;

        this.isLoading = false;
        return;
      } catch (error) {
        this.$$error$catchLoadError(error);
      } finally {
        loadingComponent.close();
      }
    },
    // 投資家のスコアを所得
    async getInvestors() {
      const result = await InvestorModule.search({
        query: {
          terms: {
            _id: this.targetUids,
          },
        },
        size: 9999,
      });

      if (result.hits.length === 0) return;

      this.investors = result.hits;
    },
    //「不動産から絞り込み」を選択して投資家を絞り込んでいた場合は、絞り込みに使った不動産がデフォルトでセットされている
    async getDefaultRealEstate() {
      if (
        !this.$route.query.defaultRealEstateId ||
        this.$route.query.defaultRealEstateId.length === 0
      )
        return;

      const result = await RealtorModule.searchByOwner(
        this.me.user.company.id,
        null
      );
      if (result.hits.length === 0) return;

      const foundItem = result.hits.find((item) => {
        return item._source.id === this.$route.query.defaultRealEstateId;
      });

      if (foundItem) {
        this.selectedRealEstates.push(foundItem);
      }
    },
    // 一括送信する
    async onSend() {
      const loadingComponent = this.$loading.open();
      try {
        let message = null;
        let thread = null;
        let newMessage = null;

        for (const uid of this.targetUids) {
          const foundItem = this.investors.find(
            (item) => item._source.uid === uid
          );

          const score = foundItem
            ? foundItem._source.user.data.latestScore.score
            : "";

          const name = foundItem ? foundItem._source.user.data.base.name : "";

          message = this.$$message$getNewMessage();
          thread = this.$$message$getNewThread();

          message.data.type = this.patternMessage.data.type;
          message.investor = await this.$$investor$loadUserWithMessage(uid);
          message.data.title = this.patternMessage.data.title;
          message.data.suggestions = this.patternMessage.data.suggestions;
          thread.contents = this.patternThread.contents;
          thread.docs = this.patternThread.docs;

          // 投資家のスコア名を入力したら、該当の投資家のスコアを置き換える
          message.data.title = this.$$createTemplateMessage$replaceScore(
            message.data.title,
            score
          );
          message.data.title = this.$$createTemplateMessage$replaceName(
            message.data.title,
            name
          );

          thread.contents = this.$$createTemplateMessage$replaceScore(
            thread.contents,
            score
          );
          thread.contents = this.$$createTemplateMessage$replaceName(
            thread.contents,
            name
          );

          newMessage = await this.$$message$createMessage(
            this.auth.uid,
            this.auth.uid,
            uid,
            message,
            thread,
            this.selectedRealEstates.map((item) => item._source.id),
            []
          );

          await this.$$message$sendMailMessageToInvestor(newMessage.id);

          if (
            this.patternMessage.data.type === this.$$ks.message.types[0].value
          ) {
            await this.$$realtor$decrementExclusiveMessageCount(this.auth.uid);
          }
        }

        this.$router.push({
          name: "realtor-setting-investors",
          query: {
            isCallback: true,
          },
        });
        this.$$toast$openSuccess("メッセージを送信しました。");
      } catch (error) {
        this.$raven.captureException(error);
        this.$$toast$openError("メッセージの送信に失敗しました。");
      } finally {
        loadingComponent.close();
      }
    },
    // カスタムテンプレートを切り替える
    onChangeCustomTemplates() {
      // 使用しない
      if (
        this.patternMessage.customTemplate ===
        this.$$ks.message.templateTypes[0].value
      ) {
        this.patternMessage.data.title = "";
        this.patternThread.contents = "";
        this.patternMessage.data.suggestions = [];
        return;
      }

      const selectedCustomTemplate = this.customTemplates.find(
        (item) => item.id === this.patternMessage.customTemplate
      );
      if (!selectedCustomTemplate) return;

      this.patternMessage.data.title = selectedCustomTemplate.data.label;
      this.patternMessage.data.suggestions =
        selectedCustomTemplate.data.suggestions;
      this.patternThread.contents = selectedCustomTemplate.data.message;
    },
    // 物件選択オーバーレイを開く
    onOpenModalSelectRealEstate() {
      this.$refs.modalSelectRealEstate.open();
    },
    // メッセージテンプレート作成オーバーレイを開く
    onOpenModalCreateTemplateMessage() {
      this.$refs.modalCreateTemplateMessage.open();
    },
    // 投資家のスコア名を入力する
    onAddInvestorScore() {
      const result = this.$$createTemplateMessage$onAddInvestorScore(
        ".bulkmessages--title",
        ".bulkmessages--content",
        this.elemFocus.previous,
        this.elemFocus.typePrevious
      );

      if (this.elemFocus.typePrevious === "textarea") {
        this.patternThread.contents = result;
      } else if (this.elemFocus.typePrevious === "textbox") {
        this.patternMessage.data.title = result;
      }
    },
    // プロフィールの項目を非公開する
    hideProfile: function (data) {
      let hideProfile = {
        name: true,
        email: true,
        phoneNumber: true,
      };

      // ZIRITZ権限 : デフォルトで表示
      if (
        this.$$user$permission.searchInvestors === this.$$authority().ziritz
      ) {
        hideProfile.name = false;
        hideProfile.email = false;
        hideProfile.phoneNumber = false;
      }

      // 専門家、提携会社権限 : 投資家から返信が来た場合にのみ表示。それ以外は「非公開」
      if (
        this.$$user$permission.searchInvestors === this.$$authority().company ||
        this.$$user$permission.searchInvestors === this.$$authority().normal
      ) {
        hideProfile.name = data
          ? data.realtor.realtors.filter(
              (item) =>
                item.realtor === this.me.user.id &&
                item.latestSendDt.investor !== null
            ).length === 0
          : true;
      }

      return hideProfile;
    },
    // 無効にするかどうか
    isDisable() {
      if (this.$$createTemplateMessage$isCheckedTerms(this.checkedTerms.length))
        return true;
      return this.$$validator$invalid;
    },
    // フォカスを切り替える
    focusChanged($event) {
      this.elemFocus = this.$$createTemplateMessage$focusChanged(
        $event,
        this.elemFocus
      );
    },
  },
  computed: {
    // ツールチップの文言を表示
    labelTooltip() {
      return `「エクスクルーシブ」は月${RealtorType.type.user.exclusiveMessageCount}件のみお送りできる特別なメッセージです。プレミアム会員および無料会員全ての投資家がメッセージを閲覧できます。「オファー」はプレミアム会員のみメッセージ閲覧可能です。（無料会員は閲覧できません）`;
    },
  },
  beforeRouteUpdate(to, from, next) {
    next();
    this.init();
  },
  mounted() {
    this.init();
  },
  created() {
    document.addEventListener("focusin", this.focusChanged);
  },
  beforeDestroy() {
    document.removeEventListener("focusin", this.focusChanged);
  },
  watch: {
    "patternThread.contents": function () {
      this.$$createTemplateMessage$adjustHeightTextarea(
        ".bulkmessages--content"
      );
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/variables.scss";

.z-section__inner {
  width: 792px;

  @include sp {
    width: unset;
  }
}

.bulkmessages {
  /deep/ .z-field {
    margin-bottom: 0px;
  }

  &--header {
    background-color: #f4f4f4;
    color: #787878;
    font-size: 24px;
    height: 48px;
    margin-bottom: 24px;
    padding: 7px 16px;
    width: 100%;
  }

  &--border {
    &__title {
      border-top: 1px solid $input-border-color;
      border-left: 1px solid $input-border-color;
      border-right: 1px solid $input-border-color;
      border-radius: 4px 4px 0 0;
    }
    &__message {
      border-bottom: 1px solid $input-border-color;
      border-left: 1px solid $input-border-color;
      border-right: 1px solid $input-border-color;
      border-radius: 0 0 4px 4px;
    }
  }

  &--type {
    /deep/ .b-radio {
      width: calc(100% - 22px);
    }

    &__wrapper {
      position: relative;
    }

    &__hint {
      display: flex;
      left: 180px;
      position: absolute;
      top: 6px;

      @include sp {
        margin-bottom: 8px;
        margin-left: 20px;
        position: unset;
      }
    }

    &__text {
      font-size: 14px;
      font-weight: bold;
      margin-left: 5px;
      margin-top: 2px;
    }

    &__number {
      color: $red;
      font-size: 14px;
      font-weight: bold;
      margin-right: 8px;
      margin-top: 2px;
    }
  }

  &--templateType {
    /deep/ .b-radio {
      width: calc(100% - 22px);
    }
  }

  &--createtemplate {
    color: #777777;
    border: 1px solid #777777;
    border-radius: 4px;
    height: 36px;
    margin: 16px 0;
    width: 210px;
  }

  &--content {
    /deep/ .textarea {
      border-bottom: none;
      resize: none;
    }
  }

  &--scorename {
    text-align: right;

    &__button {
      font-size: 14px;
      height: 32px;
      width: 220px;
    }

    &__help {
      height: 16px;
      margin: 8px 0 0 12px;
      width: 16px;
    }
  }

  &--preview {
    &--title {
      border-bottom: 1px solid $input-border-color;
      color: #aeaeae;
      font-size: 16px;
      font-weight: bold;
      line-height: 24px;
      padding: 12px 0;
      overflow-wrap: break-word;
    }
    &--suggestions {
      margin-top: 10px;
    }

    &--content {
      border: 1px solid $input-border-color;
      color: #aeaeae;
      font-size: 16px;
      min-height: 180px;
      margin-top: 20px;
      padding: 12px;
      white-space: pre-line;
      word-break: break-all;
    }
  }

  &--mention {
    color: #333333;
    font-size: 16px;
    margin: 8px 0;
  }
}

.selectRealEstate {
  &--header {
    font-size: 24px;
    font-weight: bold;
    text-align: center;
  }

  &--subheader {
    font-size: 16px;
    margin: 16px 0;
    text-align: center;
  }

  /deep/ .button {
    margin: 0 calc((100% - 200px) / 2) 40px;
    text-align: center;
    width: 200px;
  }
}

.terms {
  background-color: #e1eaf7;
  margin: 0 auto;
  padding: 24px;
  width: 700px;

  @include sp {
    bottom: -250px;
    left: 0;
    padding: 12px;
    position: absolute;
    width: 100%;

    /deep/ .z-field {
      margin-bottom: 12px;
    }
  }

  &--wrapper {
    max-width: 550px;
    margin: 0 auto;
  }

  &--title {
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 12px;
    text-align: center;

    &__number {
      color: $primary;
      display: inline-block;
      font-size: 22px;
      margin-left: 8px;
      margin-right: 2px;
    }

    &__highlight {
      color: $primary;
    }
  }

  &--subtitle {
    font-size: 12px;
    text-align: center;

    @include sp {
      text-align: left;
    }
  }

  .z-checkbox {
    margin: 0 auto;

    @include sp {
      width: 100%;
    }

    /deep/ .b-checkbox {
      margin: 4px 0;

      .control-label {
        line-height: 24px;
      }

      input[type="checkbox"]:not(:checked) + .check {
        background-color: $white;
      }
    }
  }

  .z-button {
    margin: 0 calc((100% - 344px) / 2);
    width: 344px;

    @include sp {
      margin: 0 calc((100% - 288px) / 2);
      width: 288px;
    }
  }
}
</style>
